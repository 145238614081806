import styled from 'styled-components';
import { Button } from '~/ui/kit/atoms/Button';

const ControlButton = styled(Button).attrs({
  size: 'middle',
  type: 'primary',
  br: 'm',
})`
  &.ant-btn {
    width: 100%;
    height: 100%;
    font-weight: 600;

    &:disabled {
      opacity: 0.5;
    }
  }
`;

export const PrevButton = styled(ControlButton).attrs({
  ghost: true,
})``;

export const NextButton = styled(ControlButton).attrs({})``;
