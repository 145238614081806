import { fontSize } from 'src/ui/kit/constants/fonts';
import { media } from 'src/ui/utils/media';
import styled, { css } from 'styled-components';

import { Button } from '~/ui/kit/atoms/Button';
import { gaps, getBorderRadius } from '~/ui/kit/constants/sizes';

export const Container = styled.nav`
  display: flex;
  gap: ${gaps.s}px;

  ${media.lg`
    gap: ${gaps.m}px;
  `};
`;

type MenuItemProps = {
  $isActive: boolean;
};

export const MenuItem = styled(Button).attrs<MenuItemProps>({
  type: 'link',
  noDecoration: true,
  $br: 'm',
})<MenuItemProps>`
  &.ant-btn {
    display: flex;
    align-items: center;
    gap: ${gaps.xxs}px;
    text-decoration: none;
    color: var(--eko-header-fg-color);
    padding: ${gaps.s}px ${gaps.m}px;
    ${getBorderRadius}

    ${({ $isActive }) =>
      $isActive &&
      css`
        background-color: var(--eko-header-menu-item-active-bg);
      `}
  }
`;

export const Title = styled.span.attrs({
  $fs: 'xs',
})`
  font-weight: 600;
  ${fontSize};
`;

export const Icon = styled.div`
  --header-icon-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--header-icon-height);
  width: var(--header-icon-height);

  ${media.lg`
    --header-icon-height: 24px;
  `};
`;
