import React, { CSSProperties, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import { Props } from './types';
import * as S from './PageOnboarding.styled';

const controlsCn = 'onboarding-page__step-content_container';

export const PageOnboarding: React.FC<PropsWithChildren<Props>> = ({
  onComplete,
  NextButton,
  PrevButton,
  isLoading,
  ...props
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const isFinalStep = props.steps.length - 1 === currentStepIndex;

  const setNextStep = useCallback(() => {
    if (isFinalStep) {
      onComplete();
    } else {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  }, [isFinalStep, onComplete, currentStepIndex]);

  const setPrevStep = useMemo(() => {
    if (currentStepIndex === 0) {
      return undefined;
    }
    return () => setCurrentStepIndex(currentStepIndex - 1);
  }, [currentStepIndex]);

  const getHighLightedElement = useCallback(() => {
    if (!props.visible) {
      return null;
    }

    return document.querySelector<HTMLElement>(props.steps[currentStepIndex].className);
  }, [currentStepIndex, props.steps, props.visible]);

  const nextButton = useMemo(() => {
    return <NextButton isLoading={isLoading} onClick={setNextStep} isFinalStep={isFinalStep} />;
  }, [NextButton, setNextStep, isFinalStep, isLoading]);

  const prevButton = useMemo(() => {
    if (currentStepIndex === 0 && !props.onExitSteps) {
      return null;
    }
    const onClick = currentStepIndex === 0 ? props.onExitSteps : setPrevStep;
    return <PrevButton isFirstStep={currentStepIndex === 0} onClick={onClick} disabled={!onClick || isLoading} />;
  }, [PrevButton, setPrevStep, currentStepIndex, props.onExitSteps, isLoading]);

  const [portalElement, setPortal] = useState<React.ReactNode>(null);

  useEffect(() => {
    let secondTimeout: ReturnType<typeof setTimeout>;
    const firstTimeout = setTimeout(() => {
      const step = props.steps[currentStepIndex];
      const element = document.querySelector(step.scrollTo === 'element' ? step.className : `.${controlsCn}`);
      if (element) {
        const position = step.position.includes('top') ? 'center' : 'end';

        element.scrollIntoView({
          behavior: 'smooth',
          block: position,
          inline: 'nearest',
        });

        secondTimeout = setTimeout(() => {
          window.ekoScrollOutsideToTop();
        }, 500);
      }
    }, 300);

    return () => {
      clearTimeout(firstTimeout);
      if (secondTimeout) {
        clearTimeout(secondTimeout);
      }
    };
  }, [currentStepIndex, props.steps]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'ArrowRight') {
        setNextStep();
      }
      if (e.code === 'ArrowLeft' && setPrevStep) {
        setPrevStep();
      }
    };
    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [setNextStep, setPrevStep]);

  const appContainer = useMemo(() => {
    return document.querySelector('#app main');
  }, []);

  useEffect(() => {
    const container = getHighLightedElement();

    if (container === null) {
      return;
    }

    const step = props.steps[currentStepIndex];

    let styles: CSSProperties = {};

    const offsets = {
      top: `calc(var(--q2-real-top, 0px) + ${container.offsetTop + container.clientHeight + 10}px)`,
      left: container.offsetLeft + (step.additionalLeftOffset || 0),
      right: `calc(100vw - ${container.offsetLeft + container.clientLeft + container.clientWidth}px)`,
      bottom: `calc(var(--q2-real-top, 0px) + ${container.offsetTop - 10}px)`,
    };

    switch (step.position) {
      case 'bottom': {
        styles = {
          top: offsets.top,
          left: offsets.left,
          right: offsets.right,
        };
        break;
      }
      case 'top-middle-aligned': {
        styles = {
          top: offsets.bottom,
          left: offsets.left,
          right: offsets.right,
        };
        break;
      }

      case 'bottom-right-aligned': {
        styles = {
          top: offsets.top,
          right: offsets.right,
        };
        break;
      }

      case 'bottom-left-aligned': {
        styles = {
          top: offsets.top,
          left: offsets.left,
        };
        break;
      }
    }

    const stepBaseElement = document.querySelector(step.className);

    const content = (
      <>
        <S.CommonContainer onClick={(e) => e.stopPropagation()} />
        <S.ContainerWrapper className={controlsCn} style={styles} $tooltipPosition={step.position}>
          <S.Container $desktopWidth={step.disableScrollWidth || stepBaseElement?.clientWidth}>
            {props.steps[currentStepIndex].content}
            {props.steps.length > 1 && (
              <S.Progress>
                Step {currentStepIndex + 1} of {props.steps.length}
              </S.Progress>
            )}
            <S.ControlsContainer>
              {prevButton}
              {nextButton}
            </S.ControlsContainer>
          </S.Container>
        </S.ContainerWrapper>
      </>
    );

    setPortal(content);

    const baseStyles = {
      zIndex: container.style.zIndex,
      position: container.style.position,
      backgroundColor: container.style.backgroundColor,
    };

    container.classList.add(S.elementContainerCN);

    if (!container.style.backgroundColor && step.fillDefaultBackground) {
      container.style.backgroundColor = 'white';
    }

    void (step.highlightClassName && container.classList.add(step.highlightClassName));

    return () => {
      container.classList.remove(S.elementContainerCN);
      container.style.backgroundColor = baseStyles.backgroundColor;
      void (step.highlightClassName && container.classList.remove(step.highlightClassName));
    };
  }, [getHighLightedElement, nextButton, prevButton, props.steps, currentStepIndex]);

  return (
    <>
      <S.GlobalElementStyles />
      <div className={props.className}>
        {props.children}
        {portalElement !== null && appContainer !== null && ReactDOM.createPortal(portalElement, appContainer)}
      </div>
    </>
  );
};
