import React from 'react';

import { OnboardingProps } from '../../lib';
import * as S from './NavigationButton.styled';

export const NextBtn: OnboardingProps['NextButton'] = ({ isFinalStep, onClick, isLoading }) => {
  const label = isFinalStep ? 'Got it' : 'Next';

  return (
    <S.NextButton disabled={isLoading} isLoading={isLoading} onClick={onClick}>
      {label}
    </S.NextButton>
  );
};

export const PrevBtn: OnboardingProps['PrevButton'] = ({ onClick, disabled, isFirstStep }) => {
  return (
    <S.PrevButton onClick={onClick} disabled={disabled}>
      {isFirstStep ? 'Back' : 'Prev'}
    </S.PrevButton>
  );
};
