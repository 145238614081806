import styled from 'styled-components';

import { accentFont, baseFont, fontSize } from '~/ui/kit/constants/fonts';
import { BorderRadius, gaps, getEkoBorderRadius } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';
import { Button } from '~/ui/kit/atoms/Button';
import { Popup } from '~/ui/kit/organisms/Popup';
import * as PopupStyles from '~/ui/kit/organisms/Popup/Popup.organism.styled';

import { PageOnboarding } from './lib';

export const HIGHLIGHT_BORDER_RADIUS_CN = 'highlight-border-radius';

export const getEkoBorderRadiusCN = (br?: BorderRadius): string | undefined => {
  switch (br) {
    case 'xs':
      return `${HIGHLIGHT_BORDER_RADIUS_CN}__xs`;
    case 's':
      return `${HIGHLIGHT_BORDER_RADIUS_CN}__s`;
    case 'm':
      return `${HIGHLIGHT_BORDER_RADIUS_CN}__m`;
    case 'l':
      return `${HIGHLIGHT_BORDER_RADIUS_CN}__l`;
    case 'xl':
      return `${HIGHLIGHT_BORDER_RADIUS_CN}__xl`;
  }
};

type WidthProps = {
  $width?: number;
};
export const WelcomePopup = styled(Popup).attrs<WidthProps>({ disableClose: true, autoFocus: false })<WidthProps>`
  --bs-modal-width: ${({ $width }) => ($width ? $width : 475)}px;

  & .modal-content {
    padding: ${gaps.xl}px;
  }

  ${PopupStyles.Confirm} {
    max-width: unset;
    width: 100%;
  }

  ${PopupStyles.ContentContainer} {
    display: flex;
    gap: ${gaps.m}px;
    flex-direction: column;
  }

  ${PopupStyles.Title}, ${PopupStyles.ChildrenContent} {
    margin: 0;

    ${media.md`
      margin: 0;
    `}
  }
`;

export const WelcomeTitle = styled.div.attrs({ $fs: 'xl' })`
  color: var(--eko-primary-color);
  font-weight: bold;
  ${accentFont};
  ${fontSize};
  font-size: 24px;
`;

export const WelcomeContent = styled.div.attrs({ $fs: 'm' })`
  ${baseFont};
  ${fontSize};
  font-size: 14px;
  text-align: center;
`;

export const Container = styled(PageOnboarding)`
  .${getEkoBorderRadiusCN('xs')} {
    border-radius: ${getEkoBorderRadius({ $br: 'xs' })};
  }

  .${getEkoBorderRadiusCN('s')} {
    border-radius: ${getEkoBorderRadius({ $br: 's' })};
  }

  .${getEkoBorderRadiusCN('m')} {
    border-radius: ${getEkoBorderRadius({ $br: 'm' })};
  }

  .${getEkoBorderRadiusCN('l')} {
    border-radius: ${getEkoBorderRadius({ $br: 'l' })};
  }

  .${getEkoBorderRadiusCN('xl')} {
    border-radius: ${getEkoBorderRadius({ $br: 'xl' })};
  }
`;

export const StepTitleContainer = styled.div.attrs({ $fs: 'l' })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--eko-primary-color);
  ${accentFont};
  width: 100%;
  text-align: center;
  ${fontSize};

  &:only-child {
    justify-content: center;
  }
`;

export const StepTitle = styled.div`
  width: 100%;
  text-align: center;
`;

export const Cancel = styled(Button).attrs({ type: 'link' })`
  color: var(--eko-third-color-dark) !important;
`;

export const StepContent = styled.div.attrs({ $fs: 'm' })`
  ${baseFont};
  ${fontSize};
  text-align: center;
`;
