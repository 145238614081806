import styled, { createGlobalStyle, css } from 'styled-components';
import { gaps, getBorderRadius } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';
import { baseFont, fontSize } from '~/ui/kit/constants/fonts';

import { TooltipPosition } from './types';

export const CommonContainer = styled.div`
  &:before {
    content: '';
    position: absolute;
    top: var(--q2-real-top, var(--eko-header-height, 0px));
    height: 100%;
    min-height: calc(100vh - var(--eko-header-height, 0px));
    width: 100%;
    background-color: rgba(33, 33, 33, 0.5);
    z-index: 99;
  }
`;

export const ContainerWrapper = styled.div<{ $tooltipPosition: TooltipPosition }>`
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 100;
  transition:
    top 0.3s,
    left 0.3s,
    rigth 0.3s,
    box-shadow 0.3s;

  ${({ $tooltipPosition }) => {
    switch ($tooltipPosition) {
      case 'top-middle-aligned':
        return css`
          transform: translateY(calc(-100%));
        `;

      case 'bottom':
      case 'bottom-left-aligned':
      case 'bottom-middle-aligned':
      case 'bottom-right-aligned':
        return css`
          padding-bottom: var(--eko-page-onboarding-padding-bottom, 50px);

          ${media.md`
          padding-bottom: 0;
        `};
        `;
    }
  }}
`;

type Attrs = { $desktopWidth?: number };
export const Container = styled.div.attrs<Attrs>({ $br: 'xl' })<Attrs>`
  display: flex;
  flex-direction: column;
  gap: ${gaps.m}px;
  background-color: var(--eko-background-color);
  padding: ${gaps.xl}px;
  box-sizing: border-box;
  ${getBorderRadius};

  ${({ $desktopWidth }) => css`
    ${media.md`
       max-width: ${$desktopWidth ? `${$desktopWidth}px` : 'auto'};
    `};
  `};

  @media (max-width: 767px) {
    width: calc(100vw - ${gaps.m * 2}px);
  }
`;

export const Progress = styled.div.attrs({ $fs: 'm' })`
  ${baseFont};
  ${fontSize};
  text-align: center;
  color: var(--eko-third-color);
`;

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${gaps.l}px;
`;

export const elementContainerCN = 'eko__page-onboarding_active-step';

export const GlobalElementStyles = createGlobalStyle`
  #app main {
    position: relative;
  }

  .${elementContainerCN} {
    position: relative;
    z-index: 9999;
    pointer-events: none;
    cursor: initial;

    &:after {
      pointer-events: none;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
  }
`;
